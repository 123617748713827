import "./cart-and-checkout.scss";
import "./cart-localhacks.scss"; //For actual deviations from the standard setup, which go hand-in-hand with checkout plugin

import $ from "jquery";
import * as dompack from 'dompack';


dompack.register(".wh-wrdauth__loginform button", node => {
  node.classList.add("btn");
  node.classList.add("btn-green");
});

dompack.register('.hp-checkout__addtrigger', node => node.addEventListener('click', evt =>
{
  dompack.stop(evt);
  dompack.qSA('.hp-checkout__addcode').forEach(node => node.style.height = node.scrollHeight + 'px');
}));

dompack.register(".hp-checkout__repeatedcartshow", node => node.addEventListener("click", evt =>
{
  dompack.stop(evt);
  document.documentElement.classList.add("hp--showrepeatedcart");
}));

// window.addEventListener("webshop:cartupdated", evt=>
// {
//   let webshop = evt.detail.webshop;
//   let numproducts = webshop.getCart().numarticles;
//   let shippingcosts = (document.documentElement.lang == "nl") ? 40 : 50;

//   if(webshop._calculatedcart.preshippingtotal == "0")
//     return;

//   if (webshop._calculatedcart.preshippingtotal < shippingcosts)
//   {
//     let freeshippingtotal = (shippingcosts - webshop._calculatedcart.preshippingtotal).toFixed(2).toString().replace(".", ",");
//     let freeshippingtotaltxt = (document.documentElement.lang == "nl") ? `Bestel nog € ` + freeshippingtotal + ` voor gratis verzending!`  : ` Bestell für weitere ` + freeshippingtotal + ` € und erhalte kostenlosen Versand.`;
   
//     dompack.qS('.freeshipping').textContent = freeshippingtotaltxt;
//   } else {
//     dompack.qS('.freeshipping').textContent = '';
//   }
// });

//handle options open/close click. registering centrally to avoid registering on every cart/dom change
window.addEventListener("click", evt =>
{
  let toggler = dompack.closest(evt.target, ".skw__optionstoggle");
  if(toggler)
  {
    dompack.toggleClass(toggler, "skw__optionstoggle--open");
  }
});

dompack.register('.webshop-checkout__checkoutnextbutton', node => node.addEventListener('click', evt =>
{
  window.scrollTo(0,parseInt($("#page2").offset().top));
}));

dompack.register('.webshop-checkout__paymentdeliverybutton', node => node.addEventListener('click', evt =>
{
  let elem = dompack.qS('#shipping_and_payment');
  let anchor = dompack.qS('#shipping_and_payment_anchor');
  elem.classList.remove('d-none');
  anchor.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
}));




let target = dompack.qS('#btn-filter');

if (typeof (target) != 'undefined' && target !== null)
{
  target.onclick = function (e)
  {
    let elem = dompack.qS('#productsgrid_anchor');
    elem.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
    e.preventDefault();
  }
}
